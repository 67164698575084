var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'outflow',
    'sequence',
    'product',
    'quantity' ],"formats":{
    sequence: 'Integer4',
    quantity: 'Decimal',
  },"itemsPerPage":"10","searchOnStart":true,"table":"outflow_items"}})}
var staticRenderFns = []

export { render, staticRenderFns }